import React, { useEffect } from "react"
import LayoutComponent from "../components/LayoutComponent/LayoutComponent"
import LinkList from "../components/LinkList/LinkList"
import MenuComponent from "../components/MenuComponent/MenuComponent"
import "../styles/global.scss"
import DerbyHowTo from "../assets/image/HowToRollerderby.jpg"
import { Link } from "gatsby"
import { unloadRecaptcha } from "../utils/Captcha"
import SEO from "../components/seo"

const Derby = () => {
  useEffect(() => {
    unloadRecaptcha()
  }, [])

  return (
    <LayoutComponent languageSwitchSubpage="derby">
      <SEO title="Rollderderby" />
      <MenuComponent
        selected={3}
        submenu={[
          { title: "Erklärbild", url: "#derby-image" },
          { title: "Infotext Spiel", url: "#info" },
          { title: "Verbände", url: "#verband" },
          { title: "Bundesliga", url: "https://derbyposition.com/de/" },
        ]}
      />

      <h2 className="underline-headline a-little-bit-of-margin-top">
        Rollerderby
      </h2>
      <div className="derby-how-to-image-wrapper" id="derby-image">
        <img
          className="derby-how-to-image"
          src={DerbyHowTo}
          alt="Derby how to"
        />
      </div>
      <p id="info">
        Ein Vollkontaktsport auf Rollschuhen für Frauen und Männer, die nicht
        zimperlich sind mit Technik, Taktik und Fitness. Denn Rempeln ist hier
        ausdrücklich erlaubt!
      </p>
      <p>
        Eine der wenigen Sportarten in der es kaum Altersklassifizierungen gibt,
        denn egal wie alt du bist, alle spielen in einem Team zusammen. Auch das
        Geschlecht ist egal. Dieser Sport gibt die Möglichkeit, dass es
        gemischte Teams und auch reine Frauen Teams gibt. Uns ist jeder
        willkommen!
      </p>
      <p>
        Wer Lust hat, sich in einer echten Community weltweit zu engagieren, ob
        als Spieler/in, skatende Schiedsrichter/innen, nicht skatende
        Schiedsrichter/innen oder einfach als Volontär/in, ist bei uns genau
        richtig.
      </p>
      <p>Was ist denn nun eigentlich dieses Roller Derby?</p>
      <p>
        Es gibt keinen Ball, keine Schläger, viele Regeln, Rollschuhe, Aktion…
      </p>
      <p>Dann fangen wir mal ganz am Anfang an.</p>
      <p>
        Das Spiel ist unterteilt in Spieleinheiten (Jam) die maximal 2 Minuten
        laufen, dann kommt eine Pause von 30 Sekunden, in der die 5
        Spieler/innen ausgetauscht werden können.
      </p>
      <p>
        Das Spielfeld ist eine Ovalbahn und nimmt den Platz eines
        Basketballfeldes ein.
      </p>
      <p>
        Auf dem Spielfeld (Track) sind von jeder Mannschaft 5 Spieler/innen. Die
        5 bestehen aus: einem Punktesammler (Jammer) erkennbar an den 2 Sternen
        auf dem Helm und 4 Aufhaltern (Blockern).
      </p>
      <p>
        Also nun stehen also die 2 Teams mit ihren ges. 8 Blockern und 2 Jammern
        auf dem Track. Die 8 Blocker der Teams bilden ein sogenanntes Pack
        (Rudel/Knäuel). Es erfolgt ein Pfiff und nun versuchen die Jammer durch
        das Pack hindurchzukommen. Der Jammer, der es als erstes durch das Pack
        geschafft hat, bekommt das Sonderrecht, den Jam frühzeitig zu beenden.
        Die Blocker versuchen, ihrem Jammer den Weg freizuräumen und
        gleichzeitig den gegnerischen Jammer aufzuhalten.
      </p>
      <p>
        Bei der nächsten Umrundung erhält der Jammer für jeden gegnerisch
        überholten Blocker einen Punkt.
      </p>
      <p>
        Mannschaftsgröße: ca.14 SpielerInnen
        <br />
        Skatende Schiedsrichter/innen: ca. 7<br />
        Nicht skatende Schiedsrichter: ca. 9-11
        <br />
        Wie lange geht denn so ein Spiel? Reine Spielzeit: 2 x 30min
        <br />
        Regeln zum Nachlesen und andere interessante Links:
      </p>
      <LinkList />
      <p>
        Lernt uns und diese wohl am schnellsten wachsende Sportart in
        Deutschland kennen und lasst euch vom Roller Derby Fieber packen.
      </p>
      <p>
        Das Tragen von Knie-, Ellbogen- und Handschützern, Zahnschutz sowie Helm
        ist für alle Skatenden Pflicht. Für Alle, die zum ersten Mal
        vorbeischauen haben wir immer einige Rollschuhe und Schützer übrig.
        Jede/r Spieler/in muss spätestens nach Eintritt in den Verein ihr
        eigenes Equipment besitzen.
      </p>

      <h2
        id="training"
        className="underline-headline a-little-bit-of-margin-top"
      >
        Mitmachen (Trainingszeiten)
      </h2>
      <p>
        <b>Montags</b>
        <br />
        von 18:30 - 20:00 Uhr
        <br />
        in Neckarweihingen,
        <br />
        in der Schwarzwaldhalle, Schwarzwaldstraße 2.
      </p>
      <p>
        <b>Mittwochs</b> (14-tägig in den ungeraden KWs)
        <br />
        von 20:30 - 22:00 Uhr
        <br />
        in Ludwigsburg,
        <br />
        in der Sporthalle der Fröbelschule, Fröbelstraße 24.
      </p>
      <p>
        <b>Donnerstags</b>
        <br />
        von 19:00 - 20:30 Uhr
        <br />
        in Ludwigsburg,
        <br />
        in der Sporthalle der Fröbelschule, Fröbelstraße 24.
      </p>
      <p>
        <b>Sonntags</b> (einmal im Monat)
        <br />
        Nur außerhalb der Schulferien.
        <br />
        Bei Interesse bitte bei uns die genauen Zeiten erfragen.
      </p>
      <p>
        <b>Hier genaue Karten von den jeweiligen Trainingsstätten:</b>
        <br />
        <br />
        <a
          href="https://www.google.de/maps/place/Schwarzwaldhalle+Neckarweihingen/@48.9171669,9.2203281,15z/data=!4m5!3m4!1s0x0:0xd8092b4a63daf715!8m2!3d48.9171669!4d9.2203281"
          target="_blank"
          rel="noreferrer noopener"
        >
          Schwarzwaldhalle Neckarweihingen
        </a>
        <br />
        <a
          href="https://www.google.de/maps/place/48%C2%B054%2720.3%22N+9%C2%B011%2706.2%22E/@48.905253,9.185219,208m/data=!3m1!1e3!4m2!3m1!1s0x0:0x0"
          target="_blank"
          rel="noreferrer noopener"
        >
          Fröbelhalle Ludwigsburg
        </a>
        <br />
        <br />
        Hast Du Interesse bei uns mitzumachen oder einfach mal beim Training
        dabei zu sein? <br />
        Dann melde Dich bei uns. Wir klären dann alles weitere.
        <br />
        <br />
        <a href="mailto:recruiting@barockcity.de">
          recruiting@barockcity.de
        </a>{" "}
        oder nutze unser <Link to="/contact">Kontaktformular</Link>
        <br />
        <br />
        Das Training wird professionell geführt!
        <br />
        <br />
        Das Tragen von Knie-, Ellbogen- und Handschützern, sowie Helm ist für
        die SpielerInnen Pflicht. Für alle, die zum ersten Mal vorbeischauen
        haben wir immer einige Rollschuhe und Schützer übrig. Jede/r SpielerIn
        muss spätestens nach Eintritt in den Verein eigenes Equipment besitzen.
      </p>
    </LayoutComponent>
  )
}

export default Derby
