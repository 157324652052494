import React from "react"
import "./LinkListStlye.scss"

const LinkList = ({ isEnglish }: { isEnglish?: boolean }) => {
  return (
    <div id="verband">
      <ul className="link-list-wrapper">
        <li className="link-list-header">
          {isEnglish ? "Association Germany" : "Verband Deutschland"}
        </li>
        <li>
          <a
            href="https://wriv.de/melody/index.php/sportkommissionen/rollerderby"
            target="_blank"
            rel="noreferrer noopener"
          >
            WRIV = Württembergischen Rollsport- und Inline-Verband e.V.
          </a>
          <p></p>
        </li>
        <li>
          <a
            href="https://driv.de/sportkommissionen/sportkommissionen-2/"
            target="_blank"
            rel="noreferrer noopener"
          >
            DRIV = Deutscher Rollsport und Inline-Verband e.V.
          </a>
          <p></p>
        </li>
        <li>
          <a
            href="https://rollerderbygermany.de/"
            target="_blank"
            rel="noreferrer noopener"
          >
            RDD = Sportkommission Roller Derby Deutschland
          </a>
          <p></p>
        </li>
        <li>
          <a
            href="https://derbyposition.com/de/"
            target="_blank"
            rel="noreferrer noopener"
          >
            {isEnglish
              ? "Players and rankings, as well as Bundesliga tables"
              : "Spieler und Ranglisten, sowie Bundesligatabellen"}
          </a>
          <p></p>
        </li>

        <li className="link-list-header">
          {isEnglish ? "International Associations" : "Verband International"}
        </li>
        <li>
          <a
            href="https://wftda.com/"
            target="_blank"
            rel="noreferrer noopener"
          >
            WFTDA = Women´s Flat Track Derby Assosiation
          </a>
          <p></p>
        </li>
        <li>
          <a
            href="https://wftda.org/"
            target="_blank"
            rel="noreferrer noopener"
          >
            {isEnglish
              ? "Setup of the Worldwide clubs, leagues, rankings etc..."
              : "Aufbau der Weltweiten Vereine, Rankings etc..."}
          </a>
          <p></p>
        </li>

        <li className="link-list-header">
          {isEnglish ? "Other associations" : "Weitere Verbaende"}
        </li>
        <li>
          <a href="https://mrda.org/" target="_blank" rel="noreferrer noopener">
            Men´s Roller Derby Assosiation
          </a>
          <p></p>
        </li>
      </ul>
    </div>
  )
}

export default LinkList
